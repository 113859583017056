


























































import { Component, Vue } from "vue-property-decorator";
import axios from 'axios';

@Component({
  components: {},
})
export default class Player extends Vue {
  loading=true;
  playerList=[];
  mounted() {
    let url = "/api/player/index";
    let formData = new FormData();

    axios.post(url, formData).then(response => { //加载奖项（轮次）
        let data = response.data.data;
        console.log('data->', data);
        this.loading=false;
        this.playerList=data;
    });
  }
}
